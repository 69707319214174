/**
 * ページ情報を定義
 * - ページ自体の表示権限（middleware/auth.tsで使用）
 * - サイドメニューの表示権限（layouts/company.vueで使用）
 * - rolesに定義した権限を持つユーザーのみがアクセス可能
 */
import type { RoleKey } from '~/types/roleKey'

export type PageInfo = {
  href?: string
  title: string
  icon: string
  roles?: RoleKey[]
  alias?: string[]
  child?: ChildPage[]
  badge?: {
    text: number | null
  }
}

export type ChildPage = {
  href: string
  title: string
  alias?: string[]
  roles: RoleKey[]
  icon: string
  badge?: {
    text: number | null
  }
}

export type PagesInfo = PageInfo[]

export const pagesInfo: PagesInfo = [
  // ホーム
  {
    href: '/company/dashboard/index',
    title: 'ホーム',
    icon: 'sideMenu/dashboard',
    roles: [
      'owner',
      'super_administrator',
      'limited_administrator',
      'standard_viewer',
      'restricted_viewer',
    ],
  },
  // メンバー
  {
    title: 'メンバー',
    icon: 'sideMenu/member',
    child: [
      {
        href: '/company/member/index',
        title: 'メンバー一覧',
        alias: ['/company/member/detail/:code', '/company/member/form/:code'],
        roles: [
          'owner',
          'super_administrator',
          'limited_administrator',
          'standard_viewer',
        ],
        icon: '',
      },
      {
        href: '/company/member/form',
        title: 'メンバー追加',
        roles: ['owner', 'super_administrator'],
        icon: '',
      },
      {
        href: '/company/member/anniversary',
        title: '入社1年後活躍アンケート',
        alias: ['/company/member/anniversary/:code'],
        roles: [
          'owner',
          'super_administrator',
          'limited_administrator',
          'standard_viewer',
        ],
        icon: '',
      },
      {
        href: '/company/member/csv',
        title: 'メンバー一括追加／\n更新',
        alias: ['/company/member/csv_update'],
        roles: ['owner', 'super_administrator', 'limited_administrator'],
        icon: '',
      },
      {
        href: '/company/member/import',
        title: 'メンバー取込',
        alias: ['/company/temp_member/:code'],
        roles: ['owner', 'super_administrator'],
        icon: '',
      },
    ],
  },
  // メッセージ
  {
    href: '/company/message/index',
    title: 'メッセージ',
    icon: 'sideMenu/message',
    roles: ['owner', 'super_administrator', 'limited_administrator'],
    alias: ['/company/message/detail/:code', '/company/message/form/:code'],
  },
  // 回答レポート
  {
    href: '/company/report/index',
    title: '回答レポート',
    icon: 'sideMenu/report',
    roles: ['owner', 'super_administrator'],
  },
  // お知らせ｜お知らせ
  {
    href: '/company/notification',
    title: 'お知らせ',
    icon: 'sideMenu/notification',
    roles: [
      'owner',
      'super_administrator',
      'limited_administrator',
      'standard_viewer',
      'restricted_viewer',
    ],
    alias: ['/company/notification/:code'],
  },
  // ヘルプ｜ヘルプ
  {
    href: '/company/help/faq',
    title: 'ヘルプ',
    icon: 'sideMenu/help',
    roles: [
      'owner',
      'super_administrator',
      'limited_administrator',
      'standard_viewer',
      'restricted_viewer',
    ],
    alias: ['/company/tutorial'],
  },
  // 設定
  {
    title: '設定',
    icon: 'sideMenu/settings',
    child: [
      {
        href: '/company/setting/enq',
        title: 'アンケート設定',
        roles: ['owner', 'super_administrator'],
        alias: [
          '/company/setting/enq/',
          '/company/setting/enq/notification',
          '/company/setting/enq/monthly',
          '/company/setting/enq/monthly/thanks',
          '/company/setting/enq/annual',
          '/company/setting/enq/notification/slack',
          '/company/setting/enq/notification/sms',
          '/company/setting/enq/notification/line',
          '/company/setting/enq/notification/lineworks',
          '/company/setting/enq/notification/app',
        ],
        icon: '',
      },
      {
        href: '/company/setting/message',
        title: 'メッセージ設定',
        alias: [
          '/company/setting/message/create/',
          '/company/setting/message/:code',
        ],
        roles: ['owner', 'super_administrator', 'limited_administrator'],
        icon: '',
      },
      // 組織図｜組織一覧・追加
      // 組織図｜組織検索
      // 組織図｜組織詳細（表示）
      // 組織図｜組織詳細（編集）
      // 組織図｜組織一括追加
      // 組織図｜組織長一覧・追加
      // 組織図｜上司設定
      // アカウント管理｜契約情報
      // アカウント管理｜アカウント登録
      // アカウント管理｜アカウント詳細
      // アカウント管理｜表示メンバー設定
      {
        href: '/company/setting/account',
        title: 'アカウント管理',
        alias: [
          '/company/setting/account/:hash',
          '/company/setting/account/#:hash',
          '/company/setting/account/#:hash#:hash',
          '/company/setting/account/department/detail/:id',
          '/company/setting/account/department/form/:id',
        ],
        roles: ['owner'],
        icon: '',
      },
      // セキュリティ設定｜パスワードポリシー
      // セキュリティ設定｜ネットワーク
      // セキュリティ設定｜ログ取得
      {
        href: '/company/setting/security',
        title: 'セキュリティ設定',
        roles: ['owner'],
        icon: '',
      },
      {
        href: '/company/setting/import',
        title: 'エンサービスとの連携',
        alias: ['/company/setting/talent_import'],
        roles: ['owner', 'super_administrator'],
        icon: '',
      },
    ],
  },
]
